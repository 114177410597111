export const EnablementColourBy = {
    name: 'enablement',
    title: 'Enablement',
    prefix: 'bn-',
    modal: 'show-enablement-modal',
    nodeProperty: 'enablement',
    transformData: (data) => {
        EnablementColourBy.title = 'Enablement';
        if (data.config !== 'trl' && data.config !== 'status')
            return [];
        if (data.config === 'status')
            EnablementColourBy.title = 'Enablement (OS)';
        if (data.config === 'trl' && data.effectiveDate)
            EnablementColourBy.title = `Enablement (TRL) as at ${data.effectiveDate}`,
                data.data.config = data.config;
        return data.data;
    },
    resultMapper: (node, transformedData) => {
        return transformedData[node.id]?.replace(/\W/g, '') || '';
    },
    generateItems: (data) => {
        return data.config === 'trl' ? [
            'No Enablers',
            'Unevaluated',
            'Research',
            'Concept',
            'Prototype',
            'Qualified',
            'Proven',
        ]
            :
                [
                    'No Enablers',
                    'Unevaluated',
                    'Failed / Non-operational',
                    'Degraded (Significant)',
                    'Degraded (Minor)',
                    'Fully Operational',
                ];
    },
    items: [],
};
