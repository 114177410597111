import { renderMenu } from '@/components/Generic/Graph/services/contextMenuHelper';
export class ContextToolbarService {
    contextElement;
    createContextMenu(node, parentElement, lastX, lastY) {
        this.contextElement = node;
        const baseTools = [
            { action: 'addChild', content: 'Add Child' },
            { action: 'copyNode', content: 'Copy To...' },
        ];
        // Show these options for all nodes except the root node (when parentElement is not null)
        const conditionalTools = parentElement
            ? [
                { action: 'duplicateNode', content: 'Duplicate' },
                { action: 'deleteNode', content: 'Delete Node' },
                { action: 'addPrecondition', content: 'Add Precondition' },
                { action: 'refineBehaviour', content: 'Refine Behaviour' },
            ]
            : [];
        const additionalTools = [
            { action: 'selectWithChildren', content: 'Select with Children' },
            { action: 'selectChildren', content: 'Select Children' },
            { action: 'expandBranch', content: 'Expand' },
            // TODO future work
            // {action: 'instantiateFunction', content: 'Instantiate Function'},
            // {action: 'instantiateInterface', content: 'Instantiate Interface'},
        ];
        const tools = [...baseTools, ...conditionalTools, ...additionalTools];
        return renderMenu(tools, lastX, lastY);
    }
}
